import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { gamesActions, gameActions, modalActions } from '../../actions';

import Header from '../../components/Header';
// import List from './list';

class GamesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_video: false,
      items: [],
    };

    // Bindings
  }

  componentDidMount() {
    // this.apiFindAll()
    this.props.dispatch(gameActions.setData(null));
    this.props.dispatch(gamesActions.fetchGames());
  }

  deleteGame(item) {
    const { dispatch } = this.props;
    dispatch(
      modalActions.open({
        body: (
          <p className='mt-3 text-center'>
            Do you want to delete the game: <b>{item.name}</b>?
          </p>
        ),
        buttons: [
          <button
            key='modal-btn-delete'
            className='btn btn-danger'
            onClick={() => {
              dispatch(gamesActions.removeGame(item));
              dispatch(modalActions.close());
            }}>
            Delete
          </button>,
          <button
            key='modal-btn-close'
            className='btn btn-light'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
        ],
      })
    );
  }

  render() {
    const { games } = this.props;

    const cards = games.map((i) => {
      return (
        <div className='card card-primary mb-5 border-0 shadow'>
          <div className='card-body'>
            <div className='float-right'>
              {i.status === 'saved'
                ? [
                    <Link
                      key={`edit-${i._id}`}
                      to={'/games/' + i._id}
                      className='btn btn-sm btn-outline-primary'>
                      Set up
                    </Link>,
                    <button
                      key={`remove-${i._id}`}
                      className='btn btn-sm btn-outline-danger ml-3'
                      onClick={(e) => this.deleteGame(i)}>
                      <FontAwesomeIcon
                        icon='trash-alt'
                        size='1x'
                        style={{ cursor: 'pointer' }}
                      />
                    </button>,
                  ]
                : [
                    <Link
                      key={`edit-${i._id}`}
                      to={'/games/' + i._id + '/players'}
                      className='btn btn-sm btn-dark'>
                      In Progress
                    </Link>,
                    <button
                      key={`remove-${i._id}`}
                      className='btn btn-sm btn-outline-danger ml-3'
                      onClick={(e) => this.deleteGame(i)}>
                      <FontAwesomeIcon
                        icon='trash-alt'
                        size='1x'
                        style={{ cursor: 'pointer' }}
                      />
                    </button>,
                  ]}
            </div>

            <h5 className='font-weight-normal mt-0 mb-2'>{i.name}</h5>

            {/* <p className="text-muted">...</p> */}

            <p className='mt-4 mb-0'>
              <small>
                {/* <b>Email</b>: {item.email}<br/> */}
                <b>Respondants</b>: {i.players.length}
                <br />
                <b>Author</b>:{' '}
                {i.instructor
                  ? `${i.instructor.name} ${i.instructor.lastname} ${i.instructor.email}`
                  : ''}
                <br />
                <b>Created at</b>:{' '}
                {moment(i.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                <br />
              </small>
            </p>
          </div>
        </div>
      );
    });

    return (
      <div>
        <Header />

        {/* <h2 className="sec-title">Sessions</h2> */}

        <div className='row mt-5'>
          <div className='col-12 col-lg-6 offset-lg-3'>
            <h3 className='text-center mb-5'>Surveys</h3>

            <hr className='mb-5' />

            <p className='lead text-center mb-3'>
              You can access every survey you have created, or{' '}
              <Link to='/games/new'>
                <b>start a new one</b>
              </Link>
              .
            </p>
            <p className='mb-5 text-center'>
              <button
                className='btn btn-outline-primary pl-4 pr-4'
                onClick={() => this.setState({ modal_video: true })}>
                <FontAwesomeIcon
                  icon='play'
                  size='1x'
                  style={{ cursor: 'pointer' }}
                  className='mr-2'
                />{' '}
                See How
              </button>
            </p>

            {/* You can access every survey you create, or <button className="btn btn-md btn-outline-primary">start a new one</button></p> */}

            {cards}
          </div>
        </div>

        <Modal
          isOpen={this.state.modal_video}
          centered={true}
          size={'lg'}
          toggle={() =>
            this.setState({ modal_video: !this.state.modal_video })
          }>
          <ModalHeader
            toggle={() =>
              this.setState({ modal_video: !this.state.modal_video })
            }>
            Using Aligned
          </ModalHeader>
          <ModalBody className='text-left'>
            <iframe
              title='video'
              src='https://player.vimeo.com/video/508955823'
              width='100%'
              height='360'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullScreen></iframe>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, games } = state;
  return {
    player,
    games,
  };
}

const connectedGamesPage = connect(mapStateToProps)(GamesPage);
export { connectedGamesPage as GamesPage };
