import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import posed, { PoseGroup } from 'react-pose';

import logo from '../assets/icon.png'; // relative path to image

// import logoKWhite from '../assets/k_logo_100_white.png' // "K" logo, solid white
import logoWide from '../assets/simcase_wide.png'; // relative path to image
import logoKellogg from '../assets/Kellogg_H_RGB.png'; // relative path to image
// import iconDocument from '../assets/icon_document.png' // relative path to image

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faRedoAlt,
  faHeadset,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';

// Add FontAwesome icons
library.add([
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faRedoAlt,
  faHeadset,
  faPlay,
]);

const Menu = posed.div({
  init: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    delayChildren: 250,
    staggerChildren: 100,
  },
  exit: {
    opacity: 0,
  },
});

const MenuClose = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 250 },
  },
  exit: {
    opacity: 0,
    y: -30,
    transition: { duration: 250 },
  },
});

const MenuItem = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 250 },
  },
  exit: {
    opacity: 0,
    y: 30,
    transition: { duration: 250 },
  },
});

// The Header creates links that can be used to navigate
// between routes.
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      about: false,
    };

    // Bindings
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleAbout = this.toggleAbout.bind(this);
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  toggleAbout() {
    this.setState({ about: !this.state.about, open: false });
  }

  render() {
    const { game } = this.props;
    const title = game.data ? game.data.name : '';

    return (
      <header className='app-header' style={{ margin: '0 -15px' }}>
        <nav id='main-menu' className='navbar'>
          <Link className='navbar-brand title mr-auto' to='/games'>
            {/* <img
              src={logo}
              className="logo"
              style={{ height: '30px', marginRight: '10px', marginTop: '-9px' }}
              alt="logo"
            /> */}
            ALIGNED{' '}
            {game.data && (
              <small className='d-inline-block font-weight-light ml-4 text-primary'>
                {title}
              </small>
            )}
          </Link>

          {/* <NavLink to="/about">About</NavLink> */}

          <a
            href='https://usebetta.com/help'
            className='btn btn-link btn-sm ml-auto mr-3'
            target='_blank'>
            Get Guidance
          </a>

          {game.data &&
            game.data.status === 'launched' && [
              <NavLink
                key='gd-players'
                to={`/games/${game.data._id}/players`}
                className='btn btn-outline-dark ml-5'
                activeClassName='btn-dark text-white'>
                Respondants
              </NavLink>,
              <NavLink
                key='gd-debrief'
                to={`/games/${game.data._id}/report`}
                isActive={(match, location) =>
                  location.pathname.includes('report')
                }
                className='btn btn-outline-dark ml-5 mr-3'
                activeClassName='btn-dark text-white'>
                Report
              </NavLink>,
            ]}

          {/* {!game.data && (
            <a href="/admin/games/new" className="btn btn-outline-dark ml-5 mr-3">
              New Session
            </a>
          )} */}

          {/* <div
            className="menu-toggler float-right text-primary mr-3 ml-5"
            onClick={this.toggleMenu}>
            <FontAwesomeIcon
              icon="bars"
              size="2x"
              style={{ cursor: 'pointer' }}
            />
          </div> */}
        </nav>

        {/* <PoseGroup>
          {this.state.open && (
            <Menu key="menu">
              <div className="side-menu-wrap">
                <div className="side-menu">
                  <MenuClose className="menu-close" onClick={this.toggleMenu}>
                    <FontAwesomeIcon icon="times-circle" />
                  </MenuClose>

                  <MenuItem className="menu-item" onClick={this.toggleAbout}>
                    <span>
                      <FontAwesomeIcon
                        icon="route"
                        size="3x"
                        className="icon"
                      />
                      <h5 className="mb-0">About</h5>
                      <small className="mt-0 mb-0">About this SimCase</small>
                    </span>
                  </MenuItem>
                  <MenuItem
                    className="menu-item mt-3"
                    onClick={this.toggleMenu}>
                    <NavLink to="/games" activeClassName="">
                      <FontAwesomeIcon
                        icon="list-alt"
                        size="3x"
                        className="icon"
                      />
                      <h5 className="mb-0">Game Sessions</h5>
                      <small className="mt-0 mb-0">List of games</small>
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className="menu-item mt-3"
                    onClick={this.toggleMenu}>
                    <NavLink to="/logout" activeClassName="">
                      <FontAwesomeIcon
                        icon="power-off"
                        size="3x"
                        className="icon"
                      />
                      <h5 className="mb-0">Log Out</h5>
                      <small className="mt-0 mb-0">Close your session</small>
                    </NavLink>
                  </MenuItem>
                </div>
                <div
                  onClick={this.toggleMenu}
                  className="side-menu-overlay"></div>
              </div>
            </Menu>
          )}
        </PoseGroup> */}

        {/* About Modal */}
        <Modal
          isOpen={this.state.about}
          toggle={this.toggleAbout}
          centered={true}>
          <ModalBody className='text-center'>
            <div className='mt-4 mb-4'>
              <div style={{ padding: '0 10%' }}>
                <h4 className='mt-0'>About STRUMR</h4>
                <p className='mt-5'>
                  Written by Brad Leve, Robert Macy, & Stephen Humphrey
                </p>
                <img src={logoKellogg} className='img-fluid' alt='Kellogg' />
                <p className='mt-5'>Produced by SimCase</p>
                <img
                  src={logoWide}
                  className='img-fluid'
                  style={{ maxHeight: '50px' }}
                  alt='SimCase'
                />
                <p className='mt-5 mb-0'>
                  <span
                    className='btn btn-outline-primary'
                    onClick={this.toggleAbout}>
                    Close
                  </span>
                </p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(Header);
