import React from 'react'
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// ACTIONS
// import { modalActions } from '../actions';

class ConfirmModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        // Bindings 
        this.closeModal = this.closeModal.bind(this);
    }

    // componentDidMount() {}

    closeModal(){
        const { dispatch } = this.props;
        dispatch({ type: "CLOSE_CONFIRM_MODAL" })
    }

    render() {
        const { modal } = this.props;
        return (
            <Modal isOpen={ modal.visible } toggle={ this.closeModal } centered={true}>
                { modal.title && <ModalHeader toggle={ this.closeModal }>{modal.title}</ModalHeader> }
                <ModalBody>
                    { modal.body }
                </ModalBody>
                { modal.buttons && <ModalFooter>
                    { modal.buttons }
                </ModalFooter> }
            </Modal>
        )
    }

}

function mapStateToProps( state ) {
    const { modal } = state;
    return { 
        modal
     }
}

export default connect(mapStateToProps)(ConfirmModal)