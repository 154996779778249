import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import queryString from 'query-string';

// import { playerActions, gameActions } from '../../actions';
// import { setupSocket, enterGame } from '../../helpers';

// import posed, { PoseGroup } from 'react-pose';

import scLogo from '../assets/icon.png';
// import scLogoWide from '../assets/simcase_wide.png';
import appLogo from '../assets/app_icon.png';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
    // Bindings
    this.apiGetClientData = this.apiGetClientData.bind(this);
  }

  componentDidMount() {
    this.apiGetClientData();
    console.log(this.props.match);
  }

  apiGetClientData() {
    fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
      .then((response) => response.json())
      .then((data) => this.setState({ data }));
  }

  render() {
    const { user } = this.props;
    const { data } = this.state;

    const authLink = data
      ? data.hub_url +
        '/oauth/authorize?client_id=' +
        data.client_id +
        '&response_type=code&redirect_uri=' +
        data.redirect_uri +
        '&scope=' +
        data.scope
      : '';

    return (
      <div className="row">
        <div
          className="col-12 col-lg-4 offset-lg-4 text-center"
          style={{ height: '100vh' }}>
          {data && (
            <div className="v-center">
              <img
                src={appLogo}
                style={{ maxHeight: '250px' }}
                className="img-fluid"
                alt="Aligned"
              />
              <div className="mt-5">
                <a
                  href={authLink}
                  className="btn btn-dark btn-lg"
                  style={{ width: '250px' }}>
                  Log In
                </a>
              </div>
              <p className="mt-5 mb-0 d-md-none text-center">
                <img src={scLogo} style={{ width: '25px' }} alt="" />
                <br />
                <small className="text-secondary">Developed by Simcase</small>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
