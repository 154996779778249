import io from 'socket.io-client';

let socket;
let auth = {};

export const setupSocket = (url, cb) => {
    socket = io(url);
    socket.on('connect', (s) => {
        console.log('Connected!', socket);
        cb()
    });
}

export const socketOn = (msg, cb) => {
    socket.on(msg, cb);
}

export const enterGame = (data, cb) => {
    socket.emit('enter', data, (gameData) => {
        auth = data;
        cb(gameData);
    })
}

export const enterGameBackend = (data, cb) => {
    socket.emit('enter-backend', data, (gameData) => {
        auth = data;
        cb(gameData);
    })
}