import React from 'react'
import { connect } from 'react-redux'

import { roleNames } from '../../constants'

// Actions
import { gameActions } from '../../actions';
import { setupSocket, enterGameBackend, socketOn } from '../../helpers/sockets';

import Header from '../../components/Header'
// import DancefloorChartStrumr from '../../components/DancefloorChartStrumr'
import HistoryChart from '../../components/HistoryChart'
import { DebriefMenu } from './debrief_menu'

class DebriefDancefloor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            group: 0,
            order: 0
        }
        // Bindings
    }

    componentDidMount() {
        const { dispatch, match } = this.props
        if( match.params.id ){
            // dispatch(gameActions.fetchGames(match.params.id))
            fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
            .then( res => res.json() )
            .then( data => {
                console.log(data);
                setupSocket(data.io_domain, () => {
                    enterGameBackend({ game_id: match.params.id }, socketGameData => {
                        console.log({ socketGameData })
                    });
                    // Sockets!
                    socketOn('offer', (m) => {
                        dispatch(gameActions.fetchGames(match.params.id))
                    }); // socketOn('nodeal', (m) => console.log(m) );
                })
            });
        }
    }

    render() {

        const { group } = this.state
        const { game } = this.props
        
        if( !game.data )
            return null
    
        const maxDanceLength = Math.max(...game.data.groups.map( s => s.items.length ))
        let dyads = group && game.data.groups[group-1] ? [game.data.groups[group-1]] : game.data.groups

        const dances = game.data ? dyads.filter( dyad => dyad.players.length === 2 ).map( (dyad, di) => {
            const dance = dyad.items.map( (i, ii) => { 
                return { 
                    id: 'dyad-'+di+'dot'+ii,
                    pair_index: di,
                    x: i.band + i.finish,
                    y: i.band, 
                    status: dyad.status === 'nodeal' ? ' No Deal' : ( dyad.status === 'deal' ? 'Deal' : 'In Progress' ), 
                    author: roleNames[i.author_role],
                    marker: {
                        radius: 8,
                        fillColor: i.author_role === 0 ? 'rgb(97, 46, 160)' : 'blue'
                    } 
                }
            })

            if( dyad.status === 'deal' )
                return { name: `${dyad.players[0].email} vs ${dyad.players[1].email}`, color: '#28a745', data: dance }

            if( dyad.status === 'nodeal' )
                return { name: `${dyad.players[0].email} vs ${dyad.players[1].email}`, color: '#fd5846', data: dance }

            return { name: `${dyad.players[0].email} vs ${dyad.players[1].email}`, color: 'rgba(53, 119, 242)', data: dance }

        }) : []

        

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                Offer History
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div className="mt-3 pb-5">

                <HistoryChart dances={ dances } squares={true} minRange={ maxDanceLength } popup={true} />

            </div>

        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(DebriefDancefloor)