import React from 'react';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { gamesActions, gameActions } from '../../actions';

import { GameForm } from './form';
import { GamePlayers } from './players';
import ReportPage from './report';
import { GameDebrief } from './debrief';
import GameDancefloor from './dancefloor';
import GameHistory from './history';
import GameBatna from './batna';
import GameZOPA from './zopa';
import GameBargaining from './bargaining';
import GameInterets from './interests';
import GameFirst from './first';
import GameDeals from './deals';
import GameSatisfaction from './satisfaction';

// import { GameOffers } from './offers';
// import { GameTypes } from './types';
// import { GamePareto } from './pareto';

import { GameLeaderboard } from './leaderboard';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null
    };

    // Bindings
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    this.props.dispatch(gameActions.fetchGames(id));
  }

  render() {
    const { game, match } = this.props;
    // const { item } = this.state;

    return (
      <div>
        {game && game.data && (
          <div>
            <Route exact path={match.path} component={GameForm} />
            <Route
              exact
              path={match.path + '/players'}
              component={GamePlayers}
            />
            <Route exact path={match.path + '/report'} component={ReportPage} />
            {/* <Route path={match.path + '/players'} render={ props => <GamePlayers {...props} item={item} /> } /> */}
            <Route
              exact
              path={match.path + '/debrief/overview'}
              component={GameDebrief}
            />
            <Route
              exact
              path={match.path + '/debrief/history'}
              component={GameHistory}
            />
            <Route
              exact
              path={match.path + '/debrief/zopa'}
              component={GameZOPA}
            />
            <Route
              exact
              path={match.path + '/debrief/bargaining'}
              component={GameBargaining}
            />
            <Route
              exact
              path={match.path + '/debrief/deals'}
              component={GameDeals}
            />
            <Route
              exact
              path={match.path + '/debrief/interests'}
              component={GameInterets}
            />
            <Route
              exact
              path={match.path + '/debrief/satisfaction'}
              component={GameSatisfaction}
            />
            <Route
              exact
              path={match.path + '/debrief/leaderboard'}
              component={GameLeaderboard}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameItem };
