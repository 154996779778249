import React from 'react';
import { connect } from 'react-redux';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // Bindings
  }

  // componentDidMount() {}

  render() {
    const { alert } = this.props;
    const isOpen = alert && alert.message ? true : false;
    return (
      <Toast
        isOpen={isOpen}
        className={`position-fixed border-0 shadow text-center`}
        style={{
          right: '50px',
          left: 'auto',
          bottom: '50px',
          width: '300px',
          marginLeft: '-150px',
        }}>
        {alert.title && (
          <ToastHeader className={`bg-${alert.type} text-white`}>
            {alert && (alert.title || 'Notification')}
          </ToastHeader>
        )}
        {alert.message && (
          <ToastBody className={`bg-${alert.type} text-white`}>
            {alert && alert.message}
          </ToastBody>
        )}
      </Toast>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return { alert };
}

export default connect(mapStateToProps)(Alert);
