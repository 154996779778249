import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import queryString from 'query-string';

import { authActions } from '../actions';

import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
import appLogo from '../assets/app_icon.png';

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidMount() {
    const { dispatch, location } = this.props;
    if (location.search.length > 0)
      setTimeout(dispatch(authActions.authenticate(location.search)), 2000);
    else this.setState({ error: 'Missing code' });
  }

  render() {
    return (
      <div className="row">
        <div
          className="col-12 col-lg-4 offset-lg-4 text-center"
          style={{ height: '100vh' }}>
          <div className="v-center">
            <img
              src={appLogo}
              style={{ maxHeight: '250px' }}
              className="img-fluid"
              alt="Aligned"
            />
            <div className="mt-5">
              <span
                className="btn btn-success btn-lg"
                style={{ width: '250px' }}>
                Authenticating...
              </span>
            </div>
            <p className="mt-5 mb-0 d-md-none text-center">
              <img src={scLogo} style={{ width: '25px' }} alt="" />
              <br />
              <small className="text-secondary">Developed by Simcase</small>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

const connectedAuthPage = connect(mapStateToProps)(AuthPage);
export { connectedAuthPage as AuthPage };
