import React from 'react'
import { connect } from 'react-redux'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header'
import { DebriefMenu } from './debrief_menu'

class DebriefFirst extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        const { game } = this.props

        if( !game.data )
            return null

        const deals = game.data.groups.filter( dyad => dyad.status === 'deal' )

        // Filter by role
        const generexFirst = deals.filter( dyad => dyad.items[0].author_role === 0 )
            .map( dyad => ({
                x: dyad.items[0].amount,
                y: dyad.items[dyad.items.length-1].amount
            }))
        const energeticsFirst = deals.filter( dyad => dyad.items[0].author_role === 1 )
            .map( dyad => ({
                x: dyad.items[0].amount,
                y: dyad.items[dyad.items.length-1].amount
            }))

        console.log({ generexFirst, energeticsFirst })

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                First Offer vs Final Price
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div className="mt-3">

                <HighchartsReact
                    highcharts={ Highcharts }
                    options={{ 
                        title: { text: 'First Offer vs Final Price ($M)' },
                        chart: { type: 'scatter', height: '500px' },
                        credits: { enabled: false },
                        xAxis: {
                            title: { text: 'First Offer ($M)' },
                            allowDecimals: false,
                            min: 0,
                            minRange: 600
                        },
                        yAxis: {
                            title: { text: 'Final Sale Price ($M)' },
                            allowDecimals: false,
                            min: 0,
                            minRange: 600
                        },
                        tooltip: {
                            formatter: function () {
                                const p = this.point
                                return `<b>${this.series.name}</b><br/>First Offer: $${p.x}M<br/>Final Price: $${p.y}M`
                            }
                        },
                        series: [
                            {
                                name: 'Energetics Offered First',
                                color: '#3577F2',
                                marker: {
                                    symbol: 'circle',
                                    radius: 10
                                },
                                shape: 'circle',
                                data: energeticsFirst
                            },
                            {
                                name: 'Generex Offered First',
                                color: 'purple',
                                marker: {
                                    symbol: 'circle',
                                    radius: 10
                                },
                                data: generexFirst
                            }
                        ]
                    }}
                    />
            </div>


        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(DebriefFirst)