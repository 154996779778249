import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Utils
import { validateEmail } from '../../helpers/utils';

// Actions
import { gameActions } from '../../actions';
import { roleNames } from '../../constants';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../../components/Header';
import List from './list';
import AddPlayer from './add_player';

class Players extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      pair_modal: false,
      new_players: [],
      copied: null,
    };

    // Bindings
    this.addPairModal = this.addPairModal.bind(this);
    this.handleNewPlayers = this.handleNewPlayers.bind(this);
    this.submitNewPlayers = this.submitNewPlayers.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  addPairModal() {
    this.setState({ pair_modal: !this.state.pair_modal });
  }

  handleNewPlayers(newPlayers) {
    this.setState({ new_players: newPlayers });
  }

  submitNewPlayers() {
    const { game } = this.props;
    this.props.dispatch(
      gameActions.addPlayers(game.data._id, this.state.new_players)
    );
    this.addPairModal();
    this.setState({ new_players: [] });
  }

  render() {
    const { copied } = this.state;
    const { game } = this.props;

    let players = game.data.players || [];
    const groups = game.data.groups || [];

    players = players.map((p, pi) => {
      p.name = p.name || '';
      p.lastname = p.lastname || '';
      p.group =
        1 + groups.findIndex((g) => g.players.find((gp) => gp._id === p._id));
      p.count = p.survey.length > 0 ? 'True' : 'False';
      p.role = roleNames[p.rindex];
      const ml =
        process.env.REACT_APP_GAME_URL +
        '?i=' +
        p.game_id +
        '&e=' +
        p.email +
        '&t=' +
        p.token;
      p.magiclink = (
        <div>
          <a href={ml} target='_blank' rel='noreferrer'>
            <code style={{ maxWidth: '100px' }}>{ml}</code>
          </a>
          <CopyToClipboard
            text={ml}
            onCopy={() => this.setState({ copied: pi })}>
            <button
              className={`btn btn-sm ml-3 ${
                copied === pi ? 'btn-primary' : 'btn-outline-primary'
              } float-right`}>
              {copied === pi ? 'Copied' : 'Copy'}
            </button>
          </CopyToClipboard>
        </div>
      );
      return p;
    });

    console.log(players);

    // Validate that all the players have a valid email
    const validNewPlayers =
      this.state.new_players.length === 0
        ? false
        : this.state.new_players.every((pl) => {
            return validateEmail(pl.email);
          });
    // Validate if there are repeated players
    const repeatedPlayers = this.state.new_players.filter((pl, pli) => {
      return (
        players.find((p) => p.email === pl.email) ||
        this.state.new_players.find(
          (p, pi) => p.email === pl.email && pli !== pi
        )
      );
    });

    return (
      <div>
        <Header />

        <h3 className='text-center mt-5 mb-5'>
          Respondents
          {/* <small className='float-right'>
            <button
              className='btn btn-outline-primary'
              onClick={this.addPairModal}>
              Add
            </button>
          </small> */}
        </h3>

        <p className='lead text-center'>
          Your survey has been sent. You can{' '}
          <Link to={`/games/${game.data._id}/report`}>
            <b>access your report</b>
          </Link>{' '}
          anytime or{' '}
          <a href='#' onClick={this.addPairModal}>
            <b>add a respondent</b>
          </a>{' '}
          using the link bellow:
        </p>

        <div className='input-group col-6 offset-3 mb-5 pb-3'>
          <input
            type='text'
            className='form-control border-primary text-primary'
            value={`${process.env.REACT_APP_GAME_URL}/join/${game.data._id}`}
          />
          <div className='input-group-append'>
            <CopyToClipboard
              text={`${process.env.REACT_APP_GAME_URL}/join/${game.data._id}`}
              onCopy={() => this.setState({ copied: true })}>
              <button
                className={`btn ${
                  copied ? 'btn-primary' : 'btn-outline-primary'
                }`}
                type='button'>
                {copied ? 'Copied' : 'Copy link'}
              </button>
            </CopyToClipboard>
          </div>
        </div>

        <List
          labels={['Name', 'Surname', 'Email', 'Responded', 'Survey Link']}
          fields={['name', 'lastname', 'email', 'count', 'magiclink']}
          data={players}
        />

        <Modal
          isOpen={this.state.pair_modal}
          toggle={this.addPairModal}
          centered={true}
          size={'lg'}>
          <ModalHeader>Add Respondent</ModalHeader>
          <ModalBody className='text-center'>
            <p>
              Enter the respondent information and click the <b>"Add"</b>{' '}
              button.
            </p>
            <AddPlayer onChange={this.handleNewPlayers} />
            {repeatedPlayers.length > 0 && (
              <div className='alert alert-danger mt-4'>
                The following respondents are already in this session:{' '}
                <b>{repeatedPlayers.map((p) => p.email).join(', ')}</b>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-light' onClick={this.addPairModal}>
              Cancel
            </button>
            <button
              className='btn btn-outline-primary'
              onClick={this.submitNewPlayers}
              disabled={!validNewPlayers || repeatedPlayers.length > 0}>
              Add
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedPlayersPage = connect(mapStateToProps)(Players);
export { connectedPlayersPage as GamePlayers };
