import { history } from '../helpers';
import { gameConstants } from '../constants';
// import { enterGame as wsEnterGame } from '../helpers/sockets';
import { alertActions } from '.';

export const gameActions = {
  setData,
  getData,
  createGame,
  fetchGames,
  updateGame,
  launchGame,
  addPlayers,
};

function setData(gameData) {
  return { type: gameConstants.SET_DATA, data: gameData };
}

function getData(id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + id, (res) => {
      console.log(res);
      // dispatch({ type: gameConstants.SET_DATA, data: res });
    });
  };
}

// New methods
function fetchGames(id) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + (id ? id : ''))
      .then((res) => {
        if (res.status === 401) return history.push('/logout');
        return res.json();
      })
      .then((res) => {
        dispatch({ type: gameConstants.SET_DATA, data: res });
      });
  };
}

function createGame(data) {
  return (dispatch) => {
    console.log('createGame');
    fetch(process.env.REACT_APP_API_URL + '/games', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function updateGame(data) {
  return (dispatch) => {
    dispatch({ type: gameConstants.SET_DATA, data: { status: 'saving' } });
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(alertActions.success('Successfully Updated'));
        setTimeout(() => dispatch(alertActions.clear()), 3000);
        setTimeout(
          () => dispatch({ type: gameConstants.SET_DATA, data: res }),
          500
        );
      });
  };
}

function launchGame(data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + data._id + '/launch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
  };
}

function addPlayers(game_id, data) {
  return (dispatch) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/players', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => dispatch({ type: gameConstants.SET_DATA, data: res }));
    // .then( res => dispatch({ type: gameConstants.ADD_PLAYERS, data: res }) )
  };
}

// function appendGroupData( game_id, group_id, data ){
//     return { type: gameConstants.APPEND_GROUP_DATA, data:  }
// }
