import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import posed from 'react-pose';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';

import Header from '../../components/Header';
import CSVDrop from '../../components/CSVDrop';
import CustomList from '../../components/CustomList';
import ImageUploader from '../../components/ImageUploader';
// import SetupWizard from '../../components/SetupWizard';
// import csv_template from '../../assets/simcase_csv_template.csv';
// import img_setup_roles from '../../assets/setup_roles_assignment.png';

// Actions
import { gameActions, modalActions } from '../../actions';

// Animated components
const Pill = posed.span({
  active: { color: '#FFFFFF', background: '#007bff' },
  inactive: { color: '#FFFFFF', background: '#89c2ff' },
  //   success: {
  //     color: '#007bff',
  //     background: '#FFFFFF'
  //     // border: '1px solid #007bff'
  //   }
});

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onboarding: false,
      item: this.props.game.data || {},
      changed: false,
      steps: [1, 2, 3, 4],
      labels: ['Define', 'Detail', 'Scope', 'Distribute'],
      current: 0,
      copied: false,
      groups: [],
      surveys: [],
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
    this.handleGroupsChange = this.handleGroupsChange.bind(this);
    this.confirmDeleteGroup = this.confirmDeleteGroup.bind(this);
    this.submit = this.submit.bind(this);
    this.launch = this.launch.bind(this);
    this.readCSV = this.readCSV.bind(this);
    // Wizard
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.goto = this.goto.bind(this);
    this.finish = this.finish.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.removeGroup = this.removeGroup.bind(this);
  }

  componentDidMount() {
    fetch('https://betta-cms.herokuapp.com/surveys?client_app=aligned')
      .then((response) => response.json())
      .then((surveys) => {
        this.setState({ surveys });
      })
      .catch((err) => console.log({ err }));
  }

  componentWillReceiveProps(newProps) {
    // Everytime REDUX updates the store
    // This hook will get executed and the state will get updated
    // console.log({ newProps });
    this.setState({ item: newProps.game.data });
  }

  // componentDidUpdate(prevProps, prevState){
  //     console.log(prevProps, this.props)
  // }

  handleChange(e) {
    let item = { ...this.state.item };
    item[e.target.name] =
      e.target.name === 'players_text'
        ? e.target.value.replace(/\t/g, ', ')
        : e.target.value;
    item['changed'] = true;
    this.setState({ item });
  }

  handleGroupNameChange(e, gidx) {
    const item = { ...this.state.item };
    const groups = [...item.organization_groups.map((g) => ({ ...g }))];
    groups[gidx].name = e.target.value;
    item.organization_groups = groups;
    this.setState({ item });
  }

  handleGroupsChange(data, gidx) {
    const item = { ...this.state.item };
    const groups = [...item.organization_groups.map((g) => ({ ...g }))];
    groups[gidx].items = data;
    item.organization_groups = groups;
    this.setState({ item });
  }

  setValue(field, value, next) {
    let item = { ...this.state.item };
    item[field] = value;
    item['changed'] = true;
    this.setState({ item }, () => console.log(this.state));
  }

  next(autoSave) {
    const current = this.state.current + 1;
    this.setState({ current }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      autoSave && this.submit();
    });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  goto(s, autoSave = false) {
    autoSave && this.submit();
    this.setState({ current: s });
  }

  finish() {
    if (typeof this.props.onFinish !== 'undefined') this.props.onFinish();
    else console.log('Finish');
  }

  submit() {
    this.props.dispatch(gameActions.updateGame(this.state.item));
  }

  launch() {
    this.props.dispatch(gameActions.launchGame(this.state.item));
  }

  readCSV(content) {
    let item = { ...this.state.item };
    item.players_text = content;
    this.props.dispatch(gameActions.updateGame(item));
  }

  addGroup() {
    const item = { ...this.state.item };
    const newGroups = [...item.organization_groups.map((g) => ({ ...g }))];
    newGroups.push({});
    item.organization_groups = newGroups;
    this.setState({ item });
  }

  removeGroup(gidx) {
    const { dispatch } = this.props;
    const item = { ...this.state.item };
    const newGroups = [...item.organization_groups.map((g) => ({ ...g }))];
    newGroups.splice(gidx, 1);
    item.organization_groups = newGroups;
    this.setState({ item }, () => dispatch(modalActions.close()));
  }

  confirmDeleteGroup(gidx) {
    const { dispatch } = this.props;
    dispatch(
      modalActions.open({
        body: (
          <p className='mt-3 text-center'>Do you want to delete this group?</p>
        ),
        buttons: [
          <button
            key='modal-btn-delete'
            className='btn btn-danger'
            onClick={() => this.removeGroup(gidx)}>
            Delete
          </button>,
          <button
            key='modal-btn-close'
            className='btn btn-light'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
        ],
      })
    );
  }

  render() {
    const { item, steps, labels, current, onboarding, copied, surveys } =
      this.state;

    if (item.status === 'launched')
      return <Redirect to={'/games/' + item._id + '/players'} />;

    // const okPPG = item.registered.length % item.ppg === 0 ? true : false;

    const stepStyle = {
      padding: '15px 20px',
      fontSize: '14px',
      fontWeight: '400',
      cursor: 'pointer',
    };

    const stepBubbles = steps.map((t, ti) => {
      const cols = steps.length > 0 ? 12 / steps.length : 12;
      let pose = ti <= current ? 'active' : 'inactive';
      pose = ti < this.state.current ? 'success' : pose;
      return (
        <div
          key={'step-pill-' + ti}
          onClick={(e) => this.goto(ti, true)}
          className={'step-pill text-center col-' + cols + ' ' + pose}>
          <Pill
            className='badge badge-pill badge-primary'
            pose={pose}
            style={stepStyle}>
            {t}. <span>{labels[ti]}</span>
          </Pill>
        </div>
      );
    });

    const content = [
      <div className='text-center v-center'>
        <div className='form-group p-5 border bg-white'>
          <div className='alert alert-primary mb-5'>
            Begin by determining what respondents will see on the survey.
          </div>

          <h5 className='mb-4 text-dark'>Survey</h5>
          <p className='text-muted'>
            Select the version of the survey you would like to use.
          </p>
          <div className='input-group input-group-lg'>
            <select
              name='survey'
              className='form-control'
              disabled={
                item.status === 'saving' || surveys.length === 0 ? true : false
              }
              value={item.survey || ''}
              onChange={this.handleChange}>
              {surveys.length === 0 && <option>Loading...</option>}
              {surveys.length > 0 && <option value=''>Choose survey</option>}
              {surveys.map((s) => (
                <option key={s._id} value={s._id}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>

          <h5 className='mt-5 mb-4 text-dark'>Perspective</h5>
          <p className='text-muted'>
            Select the option that best describes the group of people that will
            answer this survey.
          </p>

          <div className='btn-group-toggle' data-toggle='buttons'>
            <label
              disabled={
                item.status === 'saving' || surveys.length === 0 ? true : false
              }
              style={{ minWidth: '120px', cursor: 'pointer' }}
              className={`btn ${
                item.context === 'organization'
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              }`}>
              <input
                type='radio'
                name='context'
                value='organization'
                onClick={this.handleChange}
              />{' '}
              Organization
            </label>{' '}
            <label
              disabled={
                item.status === 'saving' || surveys.length === 0 ? true : false
              }
              style={{ minWidth: '120px', cursor: 'pointer' }}
              className={`btn ${
                item.context === 'team' ? 'btn-primary' : 'btn-outline-primary'
              }`}>
              <input
                type='radio'
                name='context'
                value='team'
                onClick={this.handleChange}
              />{' '}
              Team
            </label>
          </div>

          {/* <div className='input-group input-group-lg'>
            <select
              name='context'
              className='form-control'
              disabled={
                item.status === 'saving' || surveys.length === 0 ? true : false
              }
              value={item.context || ''}
              onChange={this.handleChange}>
              <option key='organization' value='organization'>
                Organization
              </option>
              <option key='team' value='team'>
                Team
              </option>
            </select>
          </div> */}

          <h5 className='mb-4 text-dark mt-5'>Survey Title</h5>
          <p className='text-muted'>
            Here is your chance to change your survey's name.
          </p>
          <div className='input-group input-group-lg'>
            <input
              name='name'
              className='form-control'
              placeholder='Enter survey name'
              disabled={item.status === 'saving' ? true : false}
              value={item.name || ''}
              onChange={this.handleChange}
            />
          </div>
          <h5 className='mb-4 mt-5'>Survey Description</h5>
          <p className='text-muted'>
            Add a note respondents will see on the welcome screen.
          </p>
          <div className='input-group input-group-lg'>
            <textarea
              name='description'
              rows='4'
              className='form-control'
              placeholder='A short description to let your team know what this is all about.'
              disabled={item.status === 'saving' ? true : false}
              value={item.description || ''}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='form-group p-5 border bg-white'>
          <h5 className='mb-4'>Organization Name</h5>
          <p className='text-muted'>
            Brand the tool so participants know who they are helping.
          </p>
          <div className='input-group input-group-lg'>
            <input
              name='organization'
              className='form-control'
              placeholder='Enter organization name'
              disabled={item.status === 'saving' ? true : false}
              value={item.organization || ''}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='form-group p-5 border bg-white'>
          <h5 className='mb-4'>Upload your Organization Logo</h5>
          <p className='text-muted'>
            A picture is still worth a thousand words.
          </p>
          <div className='text-center'>
            <ImageUploader
              cloud='hwwiynv2m'
              preset='wiccs7hb'
              public_id={
                item.organization_logo ? item.organization_logo.public_id : null
              }
              file={
                item.organization_logo
                  ? item.organization_logo.secure_url
                  : null
              }
              onUpload={(file) => this.setValue('organization_logo', file)}
            />
          </div>
        </div>
        <Button
          color='primary'
          onClick={this.next}
          size='lg'
          className='mt-3 mb-3'>
          Next
        </Button>
      </div>,
      <div className='text-center v-center pb-4'>
        <div className='form-group p-5 border bg-white'>
          <div className='alert alert-primary mb-5'>
            <p>Now let's customize the survey for use in your organization.</p>
            <p>
              Below you can create groups that can be used to segment your data.
              <br />
              These can be demographic factors (e.g., gender, race, etc.) or
              simply departments, divisions, offices, etc.
            </p>
            <p className='mb-0'>
              Note that you can create more than one group and
              <br />
              respondents will select from these groups during the survey.
            </p>
          </div>
          <h5 className='mb-4'>Create Groups of Interest</h5>
          {/* <p className='text-muted'>
            Include all the groups, departments, or areas whose members will
            respond to this survey.
          </p> */}
          <div className='row'>
            {item.organization_groups.map((g, gi) => {
              return (
                <div key={`group-${gi}`} className='col-8 offset-2 mb-3'>
                  <Card className='mb-3 shadow border-0'>
                    <CardBody>
                      <InputGroup size='lg' className='mb-2'>
                        <Input
                          name='text'
                          value={g.name || ''}
                          onChange={(e) => this.handleGroupNameChange(e, gi)}
                          placeholder='Group Name (e.g., Departments, Demographics)'
                        />
                        <InputGroupAddon addonType='append'>
                          {/* <Button
                            color='primary'
                            onClick={this.add}
                            // disabled={text.length === 0 ? true : false}
                          >
                            Save
                          </Button> */}
                        </InputGroupAddon>
                      </InputGroup>

                      <CustomList
                        items={g.items || []}
                        onChange={(data) => this.handleGroupsChange(data, gi)}
                      />

                      <button
                        className='btn btn-sm btn-light text-danger mt-3'
                        onClick={(e) => this.confirmDeleteGroup(gi)}>
                        Delete Group
                      </button>
                    </CardBody>
                  </Card>
                </div>
              );
            })}
            <div className='col-12 text-center mt-3'>
              <button
                className='btn btn-outline-primary'
                onClick={this.addGroup}>
                Add a new group
              </button>
            </div>
          </div>
        </div>
        {/* <div className='form-group p-5 border bg-white'>
          <h5 className='mb-4'>List Your Relevant Industries</h5>
          <p className='text-muted'>
            Include all the industries you believe might be relevant.
          </p>
          <div className='row'>
            <div className='col-8 offset-2'>
              <CustomList
                items={item.survey_industries}
                onChange={(data) => this.setValue('survey_industries', data)}
              />
            </div>
          </div>
        </div> */}
        <Button
          color='primary'
          onClick={(e) => this.next(true)}
          size='lg'
          className='mt-3 mb-3'>
          Next
        </Button>
      </div>,
      <div className='text-center v-center pb-4'>
        {/* <div className="form-group p-5 border bg-white">
          <h5 className="mb-4">Access Mode</h5>
          <p className="text-muted">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <div className="">
            <ButtonGroup size="lg">
              <Button
                color={
                  item.access_type === 'link' ? 'primary' : 'outline-primary'
                }
                onClick={(e) => this.setValue('access_type', 'link')}>
                Public Link
              </Button>
              <Button
                color={
                  item.access_type === 'csv' ? 'primary' : 'outline-primary'
                }
                onClick={(e) => this.setValue('access_type', 'csv')}>
                Invite Only
              </Button>
            </ButtonGroup>
            {item.access_type === 'link' && (
              <div className="mt-5">
                <p>
                  We've created a link you can share with your organization.
                  <br />
                  Relax, it'll be accessible during the whole proces and we'll
                  also send you a copy to your email.
                </p>
                <kbd className="d-inline-block p-2">{item.short_url}</kbd>
              </div>
            )}
          </div>
        </div> */}
        <div className='form-group p-5 border bg-white'>
          <div className='alert alert-primary mb-5'>
            <p className='mb-0'>
              You now have a survey in need of an audience.
              <br />
              Build your invite list below so we know who to send it to.
            </p>
          </div>
          <h5>Ask participants to signup using the following link:</h5>
          <div className='mt-3 mb-4'>
            <div className='input-group col-10 offset-1'>
              <input
                type='text'
                className='form-control border-primary text-primary'
                value={`${process.env.REACT_APP_GAME_URL}/join/${item._id}`}
              />
              <div className='input-group-append'>
                <CopyToClipboard
                  text={`${process.env.REACT_APP_GAME_URL}/join/${item._id}`}
                  onCopy={() => this.setState({ copied: true })}>
                  <button
                    className={`btn ${
                      copied ? 'btn-primary' : 'btn-outline-primary'
                    }`}
                    type='button'>
                    {copied ? 'Copied' : 'Copy link'}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <h5 className='mb-4'>OR</h5>

          <h5 className='mb-4'>List the people you want to invite</h5>
          <p className='text-muted'>
            Enter one respondent per line in the following format:{' '}
            <code>email, name, lastname</code>.
          </p>

          {item.access_type === 'csv' && (
            <CSVDrop onComplete={this.readCSV}>
              <div className='input-group input-group-lg'>
                <textarea
                  name='players_text'
                  className='form-control'
                  placeholder='email@example.com, John, Doe'
                  value={item.players_text || ''}
                  onChange={this.handleChange}
                  rows='15'
                  disabled={item.status === 'saving' ? true : false}></textarea>
              </div>
            </CSVDrop>
          )}

          {item.access_type === 'link' && (
            <ul class='list-group'>
              {item.registered.map((rp) => (
                <li class='list-group-item'>{rp.email}</li>
              ))}
            </ul>
          )}
        </div>
        <Button
          color='primary'
          onClick={(e) => this.next(true)}
          size='lg'
          className='mt-3 mb-3'>
          Next
        </Button>
      </div>,
      <div className='text-center v-center pb-4'>
        <div className='form-group p-5 border bg-white'>
          <h4 className='mb-4'>Launch</h4>
          <p>
            Great, you've successfully setup your survey 👏. Please review it
            one last time before we email it to those invited.
          </p>
          <table className='table table mt-5'>
            <tbody>
              <tr>
                <td className='font-weight-bolder'>Survey Title</td>
                <td>
                  {item.name}
                  <p className='text-muted'>
                    <small>
                      This text will be included when welcoming the survey
                      respondents.
                    </small>
                  </p>
                </td>
              </tr>
              <tr>
                <td className='font-weight-bolder'>Description</td>
                <td>
                  {item.description}
                  <p className='text-muted'>
                    <small>
                      This text will be included when welcoming the survey
                      respondents.
                    </small>
                  </p>
                </td>
              </tr>
              <tr>
                <td className='font-weight-bolder'>Organization</td>
                <td>
                  {item.organization}
                  <p className='text-muted'>
                    <small>
                      This text will be included when welcoming the survey
                      respondents.
                    </small>
                  </p>
                </td>
              </tr>
              <tr>
                <td className='font-weight-bolder'>Organization Groups</td>
                <td>
                  {item.organization_groups.map((g) => g.name).join(', ')}
                  <p className='text-muted'>
                    <small>
                      Survey respondents will select from this list.
                    </small>
                  </p>
                </td>
              </tr>
              <tr>
                <td className='font-weight-bolder'>Industries</td>
                <td>
                  {item.survey_industries.join(', ')}
                  <p className='text-muted'>
                    <small>
                      Survey respondents will be able to pick one option when
                      responding the survey.
                    </small>
                  </p>
                </td>
              </tr>
              {/* <tr>
                <td className='font-weight-bolder'>Access Mode</td>
                <td>
                  {item.access_type === 'csv' && <span>Invite Only</span>}
                  {item.access_type === 'csv' && (
                    <p className='text-muted'>
                      <small>
                        Survey respondents will receive a private access link by
                        email.
                      </small>
                    </p>
                  )}
                  {item.access_type === 'link' && <span>Public Link</span>}
                </td>
              </tr> */}
              <tr>
                <td className='font-weight-bolder'>Invited</td>
                <td>
                  {item.registered.length} people invited
                  {item.registered.map((r) => (
                    <p className='text-muted mb-0'>
                      <small>{r.email}</small>
                    </p>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Button
          color='outline-primary'
          onClick={this.submit}
          size='lg'
          className='mt-3 mb-3 mr-3'>
          Save for later
        </Button>
        <Button
          color='primary'
          onClick={this.launch}
          size='lg'
          className='mt-3 mb-3'>
          Launch Survey
        </Button>
      </div>,
    ];

    const currentContent = content[current];

    return (
      <div className='form-create-survey'>
        <Header />

        {/* <h2 className="mt-3 sec-title" style={{ lineHeight: 1.3 }}>
          Game Setup{' '}
          <small
            style={{
              fontSize: '12px',
              padding: '5px 8px',
              verticalAlign: 'middle'
            }}
            className={
              item.status === 'saving'
                ? 'text-capitalize badge badge-pill badge-warning'
                : 'text-capitalize badge badge-pill badge-success'
            }>
            {item.status === 'saving' ? 'saving...' : item.status}
          </small>
          <div className="float-right" style={{ lineHeight: 1 }}>
            <button
              className="btn btn-md btn-link text-dark mr-5"
              onClick={() => this.setState({ onboarding: true })}>
              <small className="font-italic">Need help?</small>
            </button>
            <button
              className="btn btn-md btn-outline-primary"
              onClick={this.submit}
              disabled={item.status === 'saving' ? true : false}>
              Save
            </button>
            <button
              className="btn btn-md btn-outline-success ml-3"
              onClick={this.launch}
              disabled={
                item.status === 'saving' || item.changed || !okPPG
                  ? true
                  : false
              }>
              Launch
            </button>
          </div>
        </h2> */}

        <div className='row mt-5'>{stepBubbles}</div>
        <div className='row pb-5'>
          <div className='col-8 offset-2 mt-5'>{currentContent}</div>
        </div>

        <div className='row mt-4'>
          <div className='col-6'>
            <div className='row'>
              <div className='col-12'></div>
              {/* {item.access_type === 'link' && (
                <div className="col-12 mt-4">
                  <div className="card">
                    <div className="card-body bg-dark text-center pt-5 pb-5 pl-5 pr-5">
                      <code style={{ fontSize: '2rem' }}>{item.short_url}</code>
                    </div>
                  </div>
                </div>
              )} */}
              {/* {item.access_type === 'csv' && (
                <div className="col-12 mt-3">
                  <div className="card mb-5">
                    <div className="card-body text-left pt-3 pb-3 pl-3 pr-3">
                      <h5 className="mb-3">Instructions</h5>
                      <ol>
                        <li className="mb-3">
                          Structure your class list in a spreadsheet as follows
                          (click{' '}
                          <b>
                            <a href={csv_template} target="_blank">
                              here
                            </a>
                          </b>{' '}
                          to download a copy):
                          <table className="table table-sm table-bordered table-striped mt-3">
                            <tbody>
                              <tr>
                                <td>player1@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                              <tr>
                                <td>player2@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                              <tr>
                                <td>player3@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                              <tr>
                                <td>player4@email.com</td>
                                <td>Name</td>
                                <td>Surname</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>
                            <b>Note</b>: The roles in this game are assigned in
                            the following order:
                          </p>
                          The first email in the list → STRUMR
                          <br />
                          The second email in the list → Lennigan's
                          <br />
                          The third email in the list → STRUMR <br />
                          The fourth email in the list → Lennigan's
                        </li>
                        <li className="mb-3">
                          Copy and paste your data into the 'Registered Players'
                          text area at right.
                        </li>
                        <li className="mb-0">
                          Click 'Save', verify the emails and names, then click
                          'Launch'.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className='col-6'></div>
        </div>

        <Modal
          isOpen={onboarding}
          toggle={() => this.setState({ onboarding: !onboarding })}
          centered={true}
          size='lg'>
          <ModalHeader>Onboarding</ModalHeader>
          <ModalBody className='text-center'>
            <iframe
              title='Onboarding'
              src='https://player.vimeo.com/video/383603029'
              width='640'
              height='360'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullscreen></iframe>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-outline-primary'
              onClick={() => this.setState({ onboarding: !onboarding })}>
              Continue
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameForm };
