import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// The Header creates links that can be used to navigate
// between routes.
class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'init',
      lastenter: null,
      loading: false,
      loaded: false,
      cloud: this.props.cloud || null,
      preset: this.props.preset || null
    };
    // Bindings
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
  }

  handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ status: 'drag', lastenter: e.target });
  }

  handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.lastenter === e.target) {
      this.setState({ status: 'init' });
    }
  }

  // This one is used for prevent default
  handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.onFileSelected({ target: { files: e.dataTransfer.files } });
  }

  onFileSelected(e) {
    const { public_id } = this.props;
    const { cloud, preset } = this.state;

    if (e.target.files.length === 0) return;

    this.setState({ loading: true, loaded: false });

    const file = e.target.files[0];
    const filename = file.name.split('.').slice(0, -1).join('.');
    const url = `https://api.cloudinary.com/v1_1/${cloud}/upload`;

    let data = new FormData();
    data.append('upload_preset', preset);
    data.append('cloud_name', cloud);
    data.append('file', file);

    if (this.props.onLoading) this.props.onLoading();

    if (public_id) data.append('public_id', `${public_id}-${filename}`);

    fetch(url, { method: 'POST', body: data })
      .then((res) => res.json())
      .then((response) => {
        this.props.onUpload && this.props.onUpload(response);
        this.setState({ loading: false, loaded: true });
      });
  }

  render() {
    const { loading, loaded, status } = this.state;
    const { file } = this.props;
    const bodyStyle = {
      init: {
        padding: '20px',
        height: 'auto',
        textAlign: 'center',
        border: '4px dashed rgb(137, 194, 255)',
        borderRadius: '10px'
      },
      drag: {
        padding: '20px',
        height: 'auto',
        textAlign: 'center',
        border: '4px dashed rgb(137, 194, 255)',
        borderRadius: '10px',
        background: '#CCC'
      }
    };
    return (
      <div>
        {loading && (
          <div className="progress mb-2">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              style={{ width: '100%' }}></div>
          </div>
        )}
        <div
          className={`status-${status}`}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDrop={this.handleDrop}
          onDragOver={this.handleDragOver}
          style={bodyStyle[status]}>
          <div>
            {file && <img className="img-fluid" src={file} alt={file} />}
          </div>

          <button
            className="btn btn-primary mt-4 mb-3"
            onClick={(e) => this.refs.fileInput.click()}>
            Select a file
          </button>

          <input
            ref="fileInput"
            style={{
              display: 'none',
              fontSize: '11px',
              borderBottom: 'none',
              width: '80%'
            }}
            type="file"
            className=""
            onChange={this.onFileSelected}
          />
          {loaded && (
            <span className="float-right">
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className="text-success"
              />
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default ImageUploader;
