import React from 'react';
import { motion } from 'framer-motion';

// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

const RangeDot = (props) => {
  // const leftPos = Math.random() * 100;
  if (!props.value) return null;
  const size = props.size ? props.size : '10px';
  const leftMargin = -1 * parseInt(size) + 1;
  const colorClass = props.colorClass ? props.colorClass : 'bg-primary';
  let leftPos = Math.round((props.value / 4) * 100);
  // leftPos = leftPos < 50 ? (leftPos / 25) * 100 - 25 : leftPos;
  // leftPos = leftPos > 50 ? (leftPos / 75) * 100 : leftPos;
  const min = props.min ? props.min : null;
  const max = props.max ? props.max : null;

  return (
    <div style={{ padding: '11px 0' }}>
      <div
        className='position-relative'
        style={{ borderTop: '1px dashed #BBB', zIndex: 999 }}>
        {min && (
          <motion.span
            className={`d-inline-block position-absolute`}
            style={{
              padding: '5px',
              left: `${Math.round((min / 4) * 100)}%`,
              top: '-5px',
              marginLeft: leftMargin + 'px',
              borderRadius: '50%',
              opacity: 0,
              zIndex: 1000,
              background: '#ed7d30',
            }}
            animate={{
              scale: [0, 1, 1],
              opacity: [0, 1, 1],
              left: ['50%', '50%', Math.round((min / 4) * 100) + '%'],
            }}
          />
        )}
        <motion.span
          className={`d-inline-block position-absolute ${colorClass}`}
          style={{
            padding: size,
            left: `${leftPos}%`,
            top: -1 * parseInt(size) + 'px',
            marginLeft: leftMargin + 'px',
            borderRadius: '50%',
            opacity: 0,
          }}
          animate={{
            scale: [0, 1, 1],
            opacity: [0, 1, 1],
            left: ['50%', '50%', leftPos + '%'],
          }}
        />
        {max && (
          <motion.span
            className={`d-inline-block position-absolute`}
            style={{
              padding: '5px',
              left: `${Math.round((max / 4) * 100)}%`,
              top: '-5px',
              marginLeft: leftMargin + 'px',
              borderRadius: '50%',
              opacity: 0,
              zIndex: 1000,
              background: '#ed7d30',
            }}
            animate={{
              scale: [0, 1, 1],
              opacity: [0, 1, 1],
              left: ['50%', '50%', Math.round((max / 4) * 100) + '%'],
            }}
          />
        )}
        {min && max && (
          <div
            className={`d-inline-block position-absolute`}
            style={{
              height: '2px',
              width: `${
                Math.round((max / 4) * 100) - Math.round((min / 4) * 100)
              }%`,
              left: `${Math.round((min / 4) * 100)}%`,
              marginTop: '-1px',
              background: '#ed7d30',
            }}></div>
        )}
      </div>
    </div>
  );
};

class DotsPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
    // Bindings
  }

  // componentDidMount() {}

  getByType(answers = [], type = '') {
    return answers.filter(
      (answere) =>
        answere && answere.question_cat.find((c) => c.category === type)
    );
  }

  answersAverage(answers) {
    return answers.length > 0
      ? answers.reduce((acc, question) => acc + question.response, 0) /
          answers.length
      : 0;
  }

  replaceTerms(string, placeholder, replace) {
    const re = new RegExp(`${placeholder}`, 'gi');
    return string.replace(re, replace);
  }

  render() {
    const { expand } = this.state;
    const { players, context } = this.props;
    // console.log({ data });

    const respondantsAverages = players
      .filter((p) => p.survey && p.survey.length > 0)
      .map((ps) => {
        // Environment
        const environmentRes = this.getByType(ps.survey, 'environment');
        const environmentAvg = this.answersAverage(environmentRes);

        // // Structure position
        // const structureRes = this.getByType(ps.survey, 'structure');
        // const structureAvg = this.answersAverage(structureRes);

        // Culture position
        const cultureRes = this.getByType(ps.survey, 'culture');
        const cultureAvg = this.answersAverage(cultureRes);

        // Culture position
        const routinesRes = this.getByType(ps.survey, 'routines');
        const routinesAvg = this.answersAverage(routinesRes);

        // Architecture position
        const architectureRes = this.getByType(ps.survey, 'architecture');
        const architectureAvg = this.answersAverage(architectureRes);

        // Strategy position
        const strategyRes = this.getByType(ps.survey, 'strategy');
        const strategyAvg = this.answersAverage(strategyRes);

        // Return position
        return {
          environment: environmentAvg || 0,
          culture: cultureAvg || 0,
          routines: routinesAvg || 0,
          architecture: architectureAvg || 0,
          strategy: strategyAvg || 0,
        };
      });

    const totalScorePerCategory = respondantsAverages.reduce(
      (acc, plavgs) => {
        const currentAvgs = { ...acc };
        currentAvgs.environment += plavgs.environment;
        currentAvgs.culture += plavgs.culture;
        currentAvgs.routines += plavgs.routines;
        currentAvgs.architecture += plavgs.architecture;
        currentAvgs.strategy += plavgs.strategy;
        return currentAvgs;
      },
      { environment: 0, culture: 0, routines: 0, architecture: 0, strategy: 0 }
    );

    // MIN & MAX
    const env_variance = {
      min: Math.min(...respondantsAverages.map((avg) => avg.environment)),
      max: Math.max(...respondantsAverages.map((avg) => avg.environment)),
    };

    const strategy_variance = {
      min: Math.min(...respondantsAverages.map((avg) => avg.strategy)),
      max: Math.max(...respondantsAverages.map((avg) => avg.strategy)),
    };

    const culture_variance = {
      min: Math.min(...respondantsAverages.map((avg) => avg.culture)),
      max: Math.max(...respondantsAverages.map((avg) => avg.culture)),
    };

    const routines_variance = {
      min: Math.min(...respondantsAverages.map((avg) => avg.routines)),
      max: Math.max(...respondantsAverages.map((avg) => avg.routines)),
    };

    const architecture_variance = {
      min: Math.min(...respondantsAverages.map((avg) => avg.architecture)),
      max: Math.max(...respondantsAverages.map((avg) => avg.architecture)),
    };

    const structure_variance = {
      min:
        (culture_variance.min +
          routines_variance.min +
          architecture_variance.min) /
        3,
      max:
        (culture_variance.max +
          routines_variance.max +
          architecture_variance.max) /
        3,
    };

    // AVG IT!
    totalScorePerCategory.environment =
      totalScorePerCategory.environment / respondantsAverages.length;
    totalScorePerCategory.culture =
      totalScorePerCategory.culture / respondantsAverages.length;
    totalScorePerCategory.routines =
      totalScorePerCategory.routines / respondantsAverages.length;
    totalScorePerCategory.architecture =
      totalScorePerCategory.architecture / respondantsAverages.length;
    totalScorePerCategory.strategy =
      totalScorePerCategory.strategy / respondantsAverages.length;
    totalScorePerCategory.structureAvg =
      (totalScorePerCategory.architecture +
        totalScorePerCategory.routines +
        totalScorePerCategory.culture) /
      3;

    // console.log({ totalScorePerCategory });

    const verticalPos = Math.round(
      (totalScorePerCategory.environment / 4) * 100
    );
    console.log({ verticalPos });

    const verticalPosStructure = Math.round(
      (totalScorePerCategory.strategy / 4) * 100
    );
    console.log({ verticalPosStructure });

    const threshold = 0.4;
    const {
      strategy,
      environment,
      structureAvg,
      architecture,
      routines,
      culture,
    } = totalScorePerCategory;

    // INSIGHTS
    const Insights = (
      <div className='card shadow'>
        <div className='card-body'>
          <h5 className='mt-0 pb-2'>Variance: Insights</h5>
          <hr />
          <div
            className='position-relative mt-4 mb-2'
            style={{ margin: '0 auto', maxWidth: '100px' }}>
            <RangeDot value={2} min={0.1} max={4} colorClass='bg-primary' />
          </div>
          <p className='font-weight-bold'>Lower Variance</p>
          <p>
            Results suggest that respondents feel similar about this criteria.
          </p>

          <div
            className='position-relative mt-5 mb-2'
            style={{ margin: '0 auto', maxWidth: '200px' }}>
            <RangeDot value={2} min={0.1} max={4} colorClass='bg-primary' />
          </div>
          <p className='font-weight-bold'>Higher Variance</p>
          <p>
            Results suggest that the experience and/or perception of respondents
            varies. The matrix chart in this report can help you identify people
            at the extremes that may be worth interviewing.
          </p>

          <div
            className='position-relative mt-5 mb-2'
            style={{ margin: '0 auto', maxWidth: '100px' }}>
            <RangeDot value={2} min={1.5} max={4} colorClass='bg-primary' />
          </div>
          <p className='font-weight-bold'>Outliers</p>
          <p>
            Results suggest that while most respondents perceive this criteria
            the same way, a few do not. Review the matrix chart to identify
            outliers and potentially interview them to see what may be driving
            their relatively unique view.
          </p>
        </div>
      </div>
    );

    return (
      <div className='row'>
        <div className='col-2 d-none d-print-block'></div>
        <div className='col-8 offset-2'>
          <table
            className='table text-center border-top-0 mb-5'
            style={{ overflow: 'hidden' }}>
            <tbody>
              <tr>
                <th className='align-middle border-top-0'>Environment</th>
                <th className='border-top-0' colSpan='2'>
                  <div className='row'>
                    <div
                      className={`col m-0 p-4 pb-5 ${
                        verticalPos < 50 ? 'bg-warning' : 'bg-light'
                      }`}>
                      Stable
                    </div>
                    <div
                      className={`col m-0 p-4 pb-5 ${
                        verticalPos >= 50 ? 'bg-warning' : 'bg-light'
                      }`}>
                      Volatile
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '-35px',
                      marginBottom: '10px',
                    }}>
                    <RangeDot
                      value={environment}
                      min={env_variance.min}
                      max={env_variance.max}
                      colorClass='bg-dark'
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th className='align-middle border-top-0'></th>
                <th className='border-top-0' colSpan='2'>
                  {players.length > 0 && (
                    <div
                      className='position-relative'
                      style={{ margin: '0 -0.75rem' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '-21px',
                          left: verticalPos >= 50 ? '50%' : '-1%',
                          width: verticalPos >= 50 ? '50%' : '51%',
                          height: '385px',
                          background: 'rgba(255,192,6,.3)',
                          zIndex: -1,
                        }}></div>
                    </div>
                  )}
                </th>
              </tr>
              <tr>
                <th></th>
                <th>Exploiter</th>
                <th>Explorer</th>
              </tr>
              <tr>
                <th>Strategy</th>
                <th className='align-middle' colSpan='2'>
                  <RangeDot
                    value={strategy}
                    min={strategy_variance.min}
                    max={strategy_variance.max}
                  />
                </th>
              </tr>
              {expand && (
                <tr>
                  <th className='align-middle border-top-0'></th>
                  <th className='border-top-0' colSpan='2'>
                    {players.length > 0 && (
                      <div className='position-relative'></div>
                    )}
                  </th>
                </tr>
              )}
              <tr>
                <th>
                  Structure/Organization Design{' '}
                  <button
                    className='btn btn-link btn-sm'
                    onClick={() => this.setState({ expand: !expand })}>
                    {expand ? '(Collapse)' : '(Expand)'}
                  </button>
                </th>
                <th className='align-middle' colSpan='2'>
                  <RangeDot
                    value={structureAvg}
                    min={structure_variance.min}
                    max={structure_variance.max}
                  />
                </th>
              </tr>
              {expand && (
                <tr>
                  <th>
                    <small className='text-muted'>Architecture</small>
                  </th>
                  <th className='align-middle' colSpan='2'>
                    <RangeDot
                      value={architecture}
                      min={architecture_variance.min}
                      max={architecture_variance.max}
                      size='6px'
                    />
                  </th>
                </tr>
              )}
              {expand && (
                <tr>
                  <th>
                    <small className='text-muted'>Routines</small>
                  </th>
                  <th className='align-middle' colSpan='2'>
                    <RangeDot
                      value={routines}
                      min={routines_variance.min}
                      max={routines_variance.max}
                      size='6px'
                    />
                  </th>
                </tr>
              )}
              {expand && (
                <tr>
                  <th>
                    <small className='text-muted'>Culture</small>
                  </th>
                  <th className='align-middle' colSpan='2'>
                    <RangeDot
                      value={culture}
                      min={culture_variance.min}
                      max={culture_variance.max}
                      size='6px'
                    />
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='col-2 d-none d-print-block'></div>

        <div className='col-10 offset-1'>
          {players.length > 0 ? Insights : null}
        </div>
      </div>
    );
  }
}
export default DotsPlot;
