import React from 'react';
import { motion } from 'framer-motion';

// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

const RangeDot = (props) => {
  // const leftPos = Math.random() * 100;
  if (!props.value) return null;
  const size = props.size ? props.size : '10px';
  const leftMargin = -1 * parseInt(size) + 1;
  const colorClass = props.colorClass ? props.colorClass : 'bg-primary';
  let leftPos = Math.round((props.value / 4) * 100);
  // leftPos = leftPos < 50 ? (leftPos / 25) * 100 - 25 : leftPos;
  // leftPos = leftPos > 50 ? (leftPos / 75) * 100 : leftPos;

  return (
    <div style={{ padding: '11px 0' }}>
      <div
        className='position-relative'
        // style={{ borderTop: '1px dashed #BBB', zIndex: 999, margin: '0 -50%' }}>
        style={{ borderTop: '1px dashed #BBB', zIndex: 999 }}>
        <motion.span
          className={`d-inline-block position-absolute ${colorClass}`}
          style={{
            padding: size,
            left: `${leftPos}%`,
            top: -1 * parseInt(size) + 'px',
            marginLeft: leftMargin + 'px',
            borderRadius: '50%',
            opacity: 0,
          }}
          animate={{
            scale: [0, 1, 1],
            opacity: [0, 1, 1],
            left: ['50%', '50%', leftPos + '%'],
          }}
        />
      </div>
    </div>
  );
};

class DotsPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
    };
    // Bindings
  }

  // componentDidMount() {}

  getByType(answers = [], type = '') {
    return answers.filter(
      (answere) =>
        answere && answere.question_cat.find((c) => c.category === type)
    );
  }

  answersAverage(answers) {
    return answers.length > 0
      ? answers.reduce((acc, question) => acc + question.response, 0) /
          answers.length
      : 0;
  }

  replaceTerms(string, placeholder, replace) {
    const re = new RegExp(`${placeholder}`, 'gi');
    return string.replace(re, replace);
  }

  render() {
    const { expand } = this.state;
    const { players, context } = this.props;
    // console.log({ data });

    const respondantsAverages = players
      .filter((p) => p.survey && p.survey.length > 0)
      .map((ps) => {
        // Environment
        const environmentRes = this.getByType(ps.survey, 'environment');
        const environmentAvg = this.answersAverage(environmentRes);

        // // Structure position
        // const structureRes = this.getByType(ps.survey, 'structure');
        // const structureAvg = this.answersAverage(structureRes);

        // Culture position
        const cultureRes = this.getByType(ps.survey, 'culture');
        const cultureAvg = this.answersAverage(cultureRes);

        // Culture position
        const routinesRes = this.getByType(ps.survey, 'routines');
        const routinesAvg = this.answersAverage(routinesRes);

        // Architecture position
        const architectureRes = this.getByType(ps.survey, 'architecture');
        const architectureAvg = this.answersAverage(architectureRes);

        // Strategy position
        const strategyRes = this.getByType(ps.survey, 'strategy');
        const strategyAvg = this.answersAverage(strategyRes);

        // Return position
        return {
          environment: environmentAvg || 0,
          culture: cultureAvg || 0,
          routines: routinesAvg || 0,
          architecture: architectureAvg || 0,
          strategy: strategyAvg || 0,
        };
      });

    const totalScorePerCategory = respondantsAverages.reduce(
      (acc, plavgs) => {
        const currentAvgs = { ...acc };
        currentAvgs.environment += plavgs.environment;
        currentAvgs.culture += plavgs.culture;
        currentAvgs.routines += plavgs.routines;
        currentAvgs.architecture += plavgs.architecture;
        currentAvgs.strategy += plavgs.strategy;
        return currentAvgs;
      },
      { environment: 0, culture: 0, routines: 0, architecture: 0, strategy: 0 }
    );

    // AVG IT!
    totalScorePerCategory.environment =
      totalScorePerCategory.environment / respondantsAverages.length;
    totalScorePerCategory.culture =
      totalScorePerCategory.culture / respondantsAverages.length;
    totalScorePerCategory.routines =
      totalScorePerCategory.routines / respondantsAverages.length;
    totalScorePerCategory.architecture =
      totalScorePerCategory.architecture / respondantsAverages.length;
    totalScorePerCategory.strategy =
      totalScorePerCategory.strategy / respondantsAverages.length;
    totalScorePerCategory.structureAvg =
      (totalScorePerCategory.architecture +
        totalScorePerCategory.routines +
        totalScorePerCategory.culture) /
      3;

    // console.log({ totalScorePerCategory });

    const verticalPos = Math.round(
      (totalScorePerCategory.environment / 4) * 100
    );
    console.log({ verticalPos });

    const verticalPosStructure = Math.round(
      (totalScorePerCategory.strategy / 4) * 100
    );
    console.log({ verticalPosStructure });

    const threshold = 0.4;
    const {
      strategy,
      environment,
      structureAvg,
      architecture,
      routines,
      culture,
    } = totalScorePerCategory;
    const insightsText = {
      strategy: {
        stay: 'On the continuum stretching from Explorer to Exploiter, your strategic orientation appears well suited to your %TERM1%’s institutional and/or competitive environment. Continue to monitor your environment to identify opportunities to further deliver on your current source(s) of value creation. Schedule periodic strategy reviews as external environments are rarely static.',
        left: "Your strategy may be somewhat misaligned with the opportunities that exist in your market(s). Your %TERM1% may benefit from narrowing its strategic focus and refining its existing sources of competitive advantage and value creation. Opportunities may exist to streamline your operations to drive bottom line growth by more efficiently serving your customer's core, repeating needs.",
        right:
          'Your strategic orientation appears somewhat misaligned with the environment in which your %TERM1% operates. Given the relative speed, volatility, and nature of competition in your environment, your %TERM1% may benefit from expanding its scope, identifying additional sources of value creation, and regularly investing in new sources of competitive advantage. Look for opportunities both within your organization and in collaboration with strategic partners to identify new opportunities, such as increasing your speed to market, working with your customers to identify additional needs that your organization may be able to meet, or promoting additional opportunities for innovative thinking from your personnel. Continue pursuing any ongoing, high margin lines of business, but consider re-tasking some of your human capital resources to explore opportunities for growth and differentiation.',
      },
      architecture: {
        stay: 'Your %TERM1%’s architecture appears to be well aligned with and supportive of its strategy. You may benefit by identifying what aspects of the %TERM1%’s architecture (for instance, the more formal versus more informal elements) are valued by your employees so those elements can be sustained in the face of any planned or unplanned changes to personnel.',
        left: 'Your %TERM1%’s architecture appears somewhat misaligned, and thus unsupportive of the strategy it is pursuing. Seek out opportunities to decentralize decision making and encourage collaboration among employees from different areas of the %TERM1%. You may consider designing less pervasive means of employee monitoring and evaluation, such as self-evaluations and fostering opportunities for more regular and less formal feedback.',
        right:
          'Your %TERM1%’s architecture appears somewhat misaligned with the strategy it is pursuing. Seek out opportunities to centralize direction of change activities while also making the monitoring system more pervasive. Continue pursuing any ways to more tightly couple business units while also hiring and promoting people focused on supporting the existing competitive advantages. Similarly, it may help to tie monetary and non-monetary incentives to the completion of specific tasks and away from innovation.',
      },
      routines: {
        stay: 'The routines that help define your %TERM1% appear to be well aligned with your current strategy. It may be helpful to audit these routines to identify which ones are informal and worth institutionalizing.',
        left: 'The routines that help define your %TERM1% are perceived to be poorly aligned with your current strategy. Your %TERM1% may benefit from an effort to identify and develop innovations arising from projects. To support these efforts, look for opportunities to increase slack in your human capital while building a group with varied strengths. In addition, your %TERM1% may also benefit from encouraging continuing education that extends beyond a defined domain and seeks a cross-pollination of ideas.',
        right:
          'The routines that help define your %TERM1% are perceived to be poorly aligned with your current strategy. Your %TERM1% may benefit from mapping and managing the interdependence among known tasks while keeping learning initiatives focused on well-defined domains. Look for opportunities to hire for specific roles and reduce organizational slack whenever possible.',
      },
      culture: {
        stay: 'Your %TERM1%’s culture is well aligned to your strategy. Take the pulse of your %TERM1% on a regular basis since it can be impacted by a wide range of intrinsic and extrinsic variables. ',
        left: 'Your %TERM1%’s culture appears somewhat misaligned with your strategic focus. Explore avenues to reward and celebrate maverick employees and those that exhibit creativity. Similarly, study alternatives to communicate a greater tolerance for failure while also profiling risks taken regardless of the eventual outcome.',
        right:
          'Your %TERM1%’s culture appears somewhat misaligned with your strategic focus. Place a greater emphasis on execution, efficiency, and reliability. Explore opportunities to reward those employee that exemplify those characteristics in their work.',
      },
    };

    // INSIGHTS
    const Insights = (
      <div className='card shadow'>
        <div className='card-body'>
          <h5 className='mt-0 pb-2'>Direction: Insights</h5>
          <table className='table text-left border-top-0'>
            <tbody>
              <tr>
                <td className='align-middle'>
                  <b>Strategy</b>
                </td>
                <td>
                  {/* {strategy} vs {environment} = <b>{strategy - environment}</b> */}
                  <br />
                  {/* STAY */}
                  {Math.abs(strategy - environment) <= threshold && (
                    <div>
                      {this.replaceTerms(
                        insightsText.strategy.stay,
                        '%TERM1%',
                        context
                      )}
                    </div>
                  )}

                  {/* MOVE TO RIGHT */}
                  {Math.abs(strategy - environment) > threshold &&
                    strategy - environment < 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.strategy.right,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                  {/* MOVE TO LEFT */}
                  {Math.abs(strategy - environment) > threshold &&
                    strategy - environment > 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.strategy.left,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                </td>
              </tr>
              <tr>
                <td className='align-middle'>
                  <b>Architecture</b>
                </td>
                <td>
                  {/* {architecture} vs {environment}={' '}<b>{architecture - environment}</b><br /> */}
                  {Math.abs(architecture - strategy) <= threshold && (
                    <div>
                      {this.replaceTerms(
                        insightsText.architecture.stay,
                        '%TERM1%',
                        context
                      )}
                    </div>
                  )}
                  {/* MOVE TO LEFT */}
                  {Math.abs(architecture - strategy) > threshold &&
                    architecture - strategy > 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.architecture.right,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                  {/* MOVE TO RIGHT */}
                  {Math.abs(architecture - strategy) > threshold &&
                    architecture - strategy < 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.architecture.left,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                </td>
              </tr>
              <tr>
                <td className='align-middle'>
                  <b>Routines</b>
                </td>
                <td>
                  {/* {routines} vs {environment}={' '}<b>{routines - environment}</b><br /> */}
                  {Math.abs(routines - strategy) <= threshold && (
                    <div>
                      {this.replaceTerms(
                        insightsText.routines.stay,
                        '%TERM1%',
                        context
                      )}
                    </div>
                  )}
                  {/* MOVE TO LEFT */}
                  {Math.abs(routines - strategy) > threshold &&
                    routines - strategy > 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.routines.right,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                  {/* MOVE TO RIGHT */}
                  {Math.abs(routines - strategy) > threshold &&
                    routines - strategy < 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.routines.left,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                </td>
              </tr>
              <tr>
                <td className='align-middle'>
                  <b>Culture</b>
                </td>
                <td>
                  {/* {culture} vs {environment}={' '}<b>{culture - environment}</b><br /> */}
                  {Math.abs(culture - strategy) <= threshold && (
                    <div>
                      {this.replaceTerms(
                        insightsText.culture.stay,
                        '%TERM1%',
                        context
                      )}
                    </div>
                  )}
                  {/* MOVE TO LEFT */}
                  {Math.abs(culture - strategy) > threshold &&
                    culture - strategy > 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.culture.right,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                  {/* MOVE TO RIGHT */}
                  {Math.abs(culture - strategy) > threshold &&
                    culture - strategy < 0 && (
                      <div>
                        {this.replaceTerms(
                          insightsText.culture.left,
                          '%TERM1%',
                          context
                        )}
                      </div>
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );

    return (
      <div className='row'>
        <div className='col-4 d-print-none'>
          <iframe
            title='video'
            src='https://player.vimeo.com/video/476312786'
            width='90%'
            height='360'
            frameBorder='0'
            allow='autoplay; fullscreen'
            allowFullScreen></iframe>
        </div>
        <div className='col-2 d-none d-print-block'></div>
        <div className='col'>
          <table
            className='table text-center border-top-0 mb-5'
            style={{ overflow: 'hidden' }}>
            <tbody>
              <tr>
                <th className='align-middle border-top-0'>Environment</th>
                <th className='border-top-0' colSpan='2'>
                  <div className='row'>
                    <div
                      className={`col m-0 p-4 pb-5 ${
                        verticalPos < 50 ? 'bg-warning' : 'bg-light'
                      }`}>
                      Stable
                    </div>
                    <div
                      className={`col m-0 p-4 pb-5 ${
                        verticalPos >= 50 ? 'bg-warning' : 'bg-light'
                      }`}>
                      Volatile
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '-35px',
                      marginBottom: '10px',
                    }}>
                    <RangeDot value={environment} colorClass='bg-dark' />
                  </div>
                </th>
              </tr>
              <tr>
                <th className='align-middle border-top-0'></th>
                <th className='border-top-0' colSpan='2'>
                  {players.length > 0 && (
                    <div
                      className='position-relative'
                      style={{ margin: '0 -0.75rem' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '-50px',
                          left: verticalPos + '%',
                          marginLeft: '-10px',
                          paddingRight: '10px',
                          height: '380px',
                          width: '1px',
                          borderRight: '2px dashed #343a40',
                          zIndex: 999,
                        }}></div>
                      <div
                        style={{
                          position: 'absolute',
                          top: '-21px',
                          left: verticalPos >= 50 ? '50%' : '-1%',
                          width: verticalPos >= 50 ? '50%' : '51%',
                          height: '385px',
                          background: 'rgba(255,192,6,.3)',
                          zIndex: -1,
                        }}></div>
                    </div>
                  )}
                </th>
              </tr>
              <tr>
                <th></th>
                <th>Exploiter</th>
                <th>Explorer</th>
              </tr>
              <tr>
                <th>Strategy</th>
                <th className='align-middle' colSpan='2'>
                  <RangeDot value={strategy} />
                </th>
              </tr>
              {expand && (
                <tr>
                  <th className='align-middle border-top-0'></th>
                  <th className='border-top-0' colSpan='2'>
                    {players.length > 0 && (
                      <div className='position-relative'>
                        <div
                          style={{
                            position: 'absolute',
                            top: '-45px',
                            left: verticalPosStructure + '%',
                            paddingRight: '10px',
                            marginLeft: '-10px',
                            height: '253px',
                            width: '1px',
                            borderRight: '2px dashed #007bff',
                            zIndex: 999,
                          }}></div>
                      </div>
                    )}
                  </th>
                </tr>
              )}
              <tr>
                <th>
                  Structure/Organization Design{' '}
                  <button
                    className='btn btn-link btn-sm'
                    onClick={() => this.setState({ expand: !expand })}>
                    {expand ? '(Collapse)' : '(Expand)'}
                  </button>
                </th>
                <th className='align-middle' colSpan='2'>
                  <RangeDot value={structureAvg} />
                </th>
              </tr>
              {expand && (
                <tr>
                  <th>
                    <small className='text-muted'>Architecture</small>
                  </th>
                  <th className='align-middle' colSpan='2'>
                    <RangeDot
                      value={architecture}
                      colorClass={
                        Math.abs(structureAvg - architecture) > threshold
                          ? 'bg-danger'
                          : 'bg-success'
                      }
                      size='6px'
                    />
                  </th>
                </tr>
              )}
              {expand && (
                <tr>
                  <th>
                    <small className='text-muted'>Routines</small>
                  </th>
                  <th className='align-middle' colSpan='2'>
                    <RangeDot
                      value={routines}
                      colorClass={
                        Math.abs(structureAvg - routines) > threshold
                          ? 'bg-danger'
                          : 'bg-success'
                      }
                      size='6px'
                    />
                  </th>
                </tr>
              )}
              {expand && (
                <tr>
                  <th>
                    <small className='text-muted'>Culture</small>
                  </th>
                  <th className='align-middle' colSpan='2'>
                    <RangeDot
                      value={culture}
                      colorClass={
                        Math.abs(structureAvg - culture) > threshold
                          ? 'bg-danger'
                          : 'bg-success'
                      }
                      size='6px'
                    />
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='col-2 d-none d-print-block'></div>

        <div className='col-10 offset-1'>
          {players.length > 0 ? Insights : null}
        </div>
      </div>
    );
  }
}
export default DotsPlot;
