/**
 * @author Juliano Castilho <julianocomg@gmail.com>
 */
import React from 'react';

class AffixWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      affix: false,
    };
    // Bindings
    this.handleScroll = this.handleScroll.bind(this);
  }

  /**
   * @return {void}
   */
  handleScroll() {
    var affix = this.state.affix;
    var offset = this.props.offset;
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (!affix && scrollTop >= offset) {
      this.setState({
        affix: true,
      });
    }

    if (affix && scrollTop < offset) {
      this.setState({
        affix: false,
      });
    }
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  /**
   * @return {void}
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    var affix = this.state.affix ? 'sticky-top' : '';
    var { className, offset, ...props } = this.props;

    return (
      <div {...props} className={className + ' ' + affix}>
        {this.props.children}
      </div>
    );
  }
}

export default AffixWrapper;
