import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import ReactGA from 'react-ga';

// import queryString from 'query-string';
import { history } from './helpers';
import ConfirmModal from './components/ComfirmModal';
import Alert from './components/Alert';
// import { HomePage } from './containers/home';

import { HomePage } from './containers/Home';
import { AuthPage } from './containers/Auth';
import { GamesPage } from './containers/games/index';
import { GameItem } from './containers/games/item';
import { GameNew } from './containers/games/new';

// BaseApp Components
// import Header from './components/Header';

import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
  }

  componentDidMount() {
    // // Google Analytics
    // if( data.ga_id ){
    //     ReactGA.initialize(data.ga_id);
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    //     history.listen(function (location) {
    //         ReactGA.pageview(window.location.pathname + window.location.search);
    //     });
    // }
    setTimeout(() => {
      if (!localStorage.getItem('scjwt')) {
        history.push('/');
      }
    }, 500);
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div id='app' className='col-12'>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={(props) => <HomePage {...props} />}
                />

                <Route
                  exact
                  path='/authenticate'
                  render={(props) => <AuthPage {...props} />}
                />

                {/* <Route exact path="/logout" render={ props => 
                                    <HomePage {...props} /> } /> */}

                <Route
                  exact
                  path='/logout'
                  component={(props) => {
                    localStorage.removeItem('scjwt');
                    props.history.push('/');
                    return null;
                  }}
                />

                <Route
                  exact
                  path='/games'
                  render={(props) => <GamesPage {...props} />}
                />

                <Route
                  exact
                  path='/games/new'
                  render={(props) => <GameNew {...props} />}
                />

                <Route
                  path='/games/:id'
                  render={(props) => <GameItem {...props} />}
                />
              </Switch>
            </Router>
          </div>
        </div>

        <ConfirmModal />
        <Alert />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

export default connect(mapStateToProps)(App);
