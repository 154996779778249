import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { ListGroup, ListGroupItem } from 'reactstrap';

// Actions
import { modalActions } from '../actions';

class CustomList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      items: props.items || [],
    };
    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.add = this.add.bind(this);
  }

  //   componentDidMount() {}

  handleChange(e) {
    let item = { ...this.state };
    item[e.target.name] = e.target.value;
    this.setState(item);
  }

  handleKeyDown(e) {
    const { text } = this.state;
    if (e.key === 'Enter' && text.trim().length > 0) {
      this.add();
    }
  }

  add() {
    const { onChange } = this.props;
    const { text, items } = this.state;
    const newItems = [...items];
    newItems.push(text.trim());
    this.setState({ items: newItems, text: '' }, () => {
      onChange && onChange(this.state.items);
    });
  }

  remove(index) {
    const { dispatch, onChange } = this.props;
    const newItems = [...this.state.items];
    newItems.splice(index, 1);
    this.setState({ items: newItems }, () => {
      onChange && onChange(this.state.items);
      dispatch(modalActions.close());
    });
  }

  confirmRemove(idx) {
    const { dispatch } = this.props;
    dispatch(
      modalActions.open({
        body: (
          <p className='mt-3 text-center'>
            Are you sure you want to delete this item?
          </p>
        ),
        buttons: [
          <button
            key='modal-btn-delete'
            className='btn btn-danger'
            onClick={() => this.remove(idx)}>
            Delete
          </button>,
          <button
            key='modal-btn-close'
            className='btn btn-light'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
        ],
      })
    );
  }

  render() {
    const { text, items } = this.state;
    const { placeholder } = this.props;
    const itemList = items.map((i, ii) => {
      return (
        <ListGroupItem key={`item-${ii}`} className='text-left'>
          <Button
            color='light text-danger'
            className='float-right'
            size='sm'
            onClick={(e) => this.confirmRemove(ii)}>
            <FontAwesomeIcon
              icon='times-circle'
              style={{ cursor: 'pointer' }}
            />
          </Button>
          {i}{' '}
        </ListGroupItem>
      );
    });
    return (
      <div>
        <ListGroup className='mb-3'>{itemList}</ListGroup>
        <InputGroup size='lg'>
          <Input
            name='text'
            value={text}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            placeholder={placeholder || ''}
          />
          <InputGroupAddon addonType='append'>
            <Button
              color='primary'
              onClick={this.add}
              disabled={text.length === 0 ? true : false}>
              Add
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

export default connect()(CustomList);
